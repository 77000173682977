const Footer = () => {
  return (
    <div className='footer'>
      <p className='footer-text'>
        Copyright &copy; 2021 | <span>neilrs.com</span>
      </p>
    </div>
  );
};

export default Footer;
