import React from 'react';

const About = () => {
  return (
    <div className='about container' id='about'>
      <h2 className='title'>About Me</h2>
      <p className='description'>
        I am Neil Bhakta, self-taught in full-stack development and mobile app
        development. I enjoy playing sports and video games.
      </p>
      {/* <p className='resume'>
        Download my{' '}
        <a href='#' className='resume-link'>
          resume
        </a>
      </p> */}
    </div>
  );
};

export default About;
