import React from 'react';

const Card = ({ img_url, title, description, pro_link }) => {
  return (
    <div className='card'>
      {/* ? img_url : 'https://picsum.photos/300/200' */}
      {img_url ? <img src={img_url} alt='' className='card-img' /> : ''}
      <h3 className='card-title'>{title ? title : 'title'}</h3>
      <p className='card-description'>
        {description
          ? description
          : 'Lorem ipsum, dolor sit amet consectetur adipisicing elit. Neque tempora consequatur perspiciatis vel nesciunt dolorem, expedita impedit quis quaerat numquam.'}
      </p>

      {pro_link ? (
        <a
          className='card-description card-link'
          href={pro_link}
          target='_blank'
        >
          Live
        </a>
      ) : (
        ''
      )}
    </div>
  );
};

export default Card;
