import React, { useEffect, useRef } from 'react';
import { NavLink } from 'react-router-dom';
import {
  AiOutlineLinkedin,
  AiFillGithub,
  AiOutlineClose,
} from 'react-icons/ai';
import { BsThreeDotsVertical } from 'react-icons/bs';

const Header = () => {
  const navRef = useRef();
  const menuRef = useRef();

  useEffect(() => {
    toggleNav();
  }, []);

  const toggleNav = () => {
    menuRef.current.classList.toggle('hide');
    navRef.current.classList.toggle('show-nav');
  };

  return (
    <header className='navbar'>
      <nav className='container nav-items'>
        <div className='brand'>
          <p className='logo'>
            <NavLink to='/'>Neil Bhakta</NavLink>
          </p>
        </div>
        <div className='nav-links'>
          <div className='menu hide' onClick={toggleNav} ref={menuRef}>
            <BsThreeDotsVertical size={24} />
          </div>
          <ul className='links show-nav' ref={navRef}>
            <div className='close' onClick={toggleNav}>
              <AiOutlineClose className='close-icon' />
            </div>
            <li className='link-wrapper'>
              <NavLink
                exact
                className='link'
                activeClassName='active'
                to='/'
                onClick={toggleNav}
              >
                Home
              </NavLink>
            </li>
            <li className='link-wrapper'>
              <NavLink
                className='link'
                activeClassName='active'
                to='/about'
                onClick={toggleNav}
              >
                About
              </NavLink>
            </li>
            <li className='link-wrapper'>
              <NavLink
                className='link'
                activeClassName='active'
                to='/projects'
                onClick={toggleNav}
              >
                Projects
              </NavLink>
            </li>
            <li className='link-wrapper'>
              <NavLink
                exact
                className='link'
                activeClassName='active'
                to='/contact'
                onClick={toggleNav}
              >
                Contact Me
              </NavLink>
            </li>
            <li className='link-wrapper'>
              <div className='nav-social-links'>
                <a
                  className='nav-button'
                  href='https://www.linkedin.com/in/neil-bhakta-b680301ab/'
                  target='_blank'
                >
                  <AiOutlineLinkedin className='nav-btn-link' />
                </a>
                <a
                  className='nav-button'
                  href='https://github.com/RogueSoul7'
                  target='_blank'
                >
                  <AiFillGithub className='nav-btn-link' />
                </a>
              </div>
            </li>
          </ul>
        </div>
      </nav>
    </header>
  );
};

export default Header;
