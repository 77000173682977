import {
  AiFillLinkedin,
  AiFillGithub,
  AiOutlineDownload,
} from 'react-icons/ai';

const AboutPage = () => {
  return (
    <div className='spacer about-page container'>
      <h1 className='title about-page-title'>About</h1>
      <div className='about-links'>
        <div className='social-links'>
          <a
            className='about-button'
            href='https://www.linkedin.com/in/neil-bhakta-b680301ab/'
            target='_blank'
          >
            <AiFillLinkedin className='about-btn-link' />
          </a>
          <a
            className='about-button'
            href='https://github.com/RogueSoul7'
            target='_blank'
          >
            <AiFillGithub className='about-btn-link' />
          </a>
        </div>
        <div className='about-resume-link'>
          <p className='about-p'>Download my</p>
          <a
            className='about-btn'
            href={process.env.PUBLIC_URL + '/Resume.pdf'}
            target='_blank'
          >
            resume
            <AiOutlineDownload className='download' />
          </a>
        </div>
      </div>
      <p className='about-summary'>
        I am a self-taught full-stack developer, and mobile app developer
      </p>
      <div className='main-skills'>
        <p className='about-section'>Main Skills</p>
        <div className='about-grid-group'>
          <ul className='about-ui group-ui'>
            <li className='about-li'>
              Frontend Development
              <br />
              Backend development
            </li>
            <li className='about-li'>
              Javascript, CSS, HTML
              <br />
              React, React Native
            </li>
            <li className='about-li'>
              Python, Django
              <br />
              Django Rest Framwork
            </li>
            <li className='about-li'>SQL, MongoDB</li>
          </ul>
        </div>
      </div>
      <div className='tools-years'>
        <p className='about-section'>Tools/years</p>
        <div className='about-grid'>
          <ul className='about-ui'>
            <li className='about-li group-li'>Python/+1</li>
            <li className='about-li group-li'>Javascript/+1</li>
            <li className='about-li group-li'>HTML/+1</li>
            <li className='about-li group-li'>CSS/+1</li>
          </ul>
          <ul className='about-ui'>
            <li className='about-li group-li'>React/+1</li>
            <li className='about-li group-li'>React Native/+1</li>
            <li className='about-li group-li'>Django/+1</li>
            <li className='about-li group-li'>Django Rest Framework/+1</li>
          </ul>
          <ul className='about-ui'>
            <li className='about-li group-li'>SQL/+1</li>
            <li className='about-li group-li'>MongoDB/&lt;1</li>
          </ul>
        </div>
      </div>
      <div className='lang'>
        <p className='about-section'>Languages</p>
        <div className='about-grid'>
          <ul className='about-ui'>
            <li className='about-li group-li comment'>&#47;&#47; fluent</li>
            <li className='about-li group-li lang'>
              <span>en-US</span> English
            </li>
            <li className='about-li group-li lang'>
              <span>gu-IND</span> Gujarati
            </li>
          </ul>
          <ul className='about-ui'>
            <li className='about-li group-li comment'>
              &#47;&#47; intermediate
            </li>
            <li className='about-li group-li lang'>
              <span>hi-IND</span> Hindi
            </li>
          </ul>
        </div>
      </div>
      <div className='extra'>
        <p className='about-section'>Also busy with</p>
        <div className='about-grid-group'>
          <ul className='about-ui group-ui'>
            <li className='about-li'>Sports</li>
            <li className='about-li'>Video games</li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AboutPage;
