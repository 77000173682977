import React from 'react';
import Card from './Card';
import { Link } from 'react-router-dom';
import recipe from '../images/SelectedProjects/recipe_book.png';

const SelectedProjects = () => {
  return (
    <div className='selected-projects container'>
      <h2 className='title'>Selected Projects</h2>
      <div className='cards'>
        <Card
          img_url={recipe}
          title='Online Recipe Book'
          description='This is a website where you can store you recipes and choose whether it is private or public to other. You can follow others to get their recipes in your feed.'
          pro_link='https://www.myawesomerecipeapp.com'
        />
        <Card
          img_url=''
          title='GrabAByte'
          description='Software as a service, which allows restaurnat owners to manage their restaurant profile and work order. Employees see their work order on their assigned tablet.'
          pro_link='https://www.youtube.com/watch?v=DBXG3MMCFwo'
        />
        <Card
          img_url=''
          title='Home Inventory'
          description='This is a home inventory application where you can store your product information and product location in your home. This project was inspired by GrabAByte inventory system idea.'
          pro_link='https://github.com/RogueSoul7/home-inventory'
        />
      </div>
      <p className='all-projects'>
        View my other <Link to='/projects'>projects</Link>
      </p>
    </div>
  );
};

export default SelectedProjects;
