import React from 'react';
import { AiOutlineLinkedin, AiFillGithub } from 'react-icons/ai';

const Main = () => {
  return (
    <main className='main'>
      <div className='main-content container'>
        <p className='greet'>
          Hello, I'm <span>Neil Bhakta</span>
        </p>
        <div className='main-social-links'>
          <a
            className='main-button'
            href='https://www.linkedin.com/in/neil-bhakta-b680301ab/'
            target='_blank'
          >
            <AiOutlineLinkedin className='main-btn-link' />
          </a>
          <a
            className='main-button'
            href='https://github.com/RogueSoul7'
            target='_blank'
          >
            <AiFillGithub className='main-btn-link' />
          </a>
        </div>
      </div>
    </main>
  );
};

export default Main;
