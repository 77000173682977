const Projects = () => {
  return (
    <div className='spacer projects-page container'>
      <h1 className='title'>Projects</h1>
      <p className='project-p'>
        Currently this page is under maintence. Check back soon for update.
      </p>
    </div>
  );
};

export default Projects;
