import { useState } from 'react';
import emailjs from 'emailjs-com';

const Contact = () => {
  const [userEmail, setUserEmail] = useState('');
  const [name, setName] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');

  const updateInput = (e, fun) => {
    fun(e.target.value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (userEmail === '' || name === '' || subject === '' || message === '')
      return;

    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_ID,
        process.env.REACT_APP_TEMPLATE_ID,
        e.target,
        process.env.REACT_APP_USER_ID
      )
      .then(
        (result) => {
          alert('Thank you for contacting me. Email has been sent!');
        },
        (error) => {
          alert('Something went wrong, please try again.');
        }
      );

    setUserEmail('');
    setName('');
    setSubject('');
    setMessage('');
  };

  return (
    <div className='container contact' id='contact'>
      <h2 className='title'>Get in touch with me</h2>
      <form className='contact-form' onSubmit={sendEmail}>
        <div className='contact-form-inputs'>
          <div className='input input-email'>
            <label className='form-label' htmlFor='email'>
              Email
            </label>
            <input
              className='user-input form-input'
              type='email'
              name='email'
              onChange={(e) => updateInput(e, setUserEmail)}
              value={userEmail}
              placeholder='Your email address'
              autoComplete='off'
              required
            />
          </div>
          <div className='input input-name'>
            <label className='form-label' htmlFor='name'>
              Name
            </label>
            <input
              className='user-input form-input'
              type='text'
              name='name'
              onChange={(e) => updateInput(e, setName)}
              value={name}
              placeholder='Your name'
              autoComplete='off'
              required
            />
          </div>
          <div className='input input-subject'>
            <label className='form-label' htmlFor='subject'>
              Subject
            </label>
            <input
              className='user-input form-input'
              type='text'
              name='subject'
              onChange={(e) => updateInput(e, setSubject)}
              value={subject}
              placeholder='Subject'
              autoComplete='off'
              required
            />
          </div>
          <div className='input input-message'>
            <label className='form-label' htmlFor='message'>
              Message
            </label>
            <textarea
              className='user-input form-input-textarea'
              name='message'
              onChange={(e) => updateInput(e, setMessage)}
              value={message}
              rows={8}
              placeholder='Message ...'
              required
            />
          </div>
        </div>
        <div className='submit-form'>
          <button className='submit' type='submit'>
            Send email
          </button>
        </div>
      </form>
    </div>
  );
};

export default Contact;
